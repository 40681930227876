/* @import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap'); */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer base {
  body {
    font-family: 'Questrial', sans-serif;
  }
} */


@layer components {
  .t-form {
    @apply mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 dark:border-gray-700 dark:text-gray-300 rounded-md dark:bg-gray-800
  }
  .t-transaksi {
    @apply w-full text-sm bg-brand-100 dark:bg-gray-900
  }
  .t-transaksi th {
    @apply bg-brand-900 text-brand-200 font-medium
  }
  .t-transaksi td, .t-transaksi th {
    @apply p-2 align-top
  }
}